@keyframes dtb-spinner {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @-o-keyframes dtb-spinner {
    100% {
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-ms-keyframes dtb-spinner {
    100% {
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes dtb-spinner {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes dtb-spinner {
    100% {
      -moz-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  div {
    &.dt-button-info {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 400px;
      margin-top: -100px;
      margin-left: -200px;
      background-color: white;
      border: 2px solid #111;
      box-shadow: 3px 3px 8px $black-3;
      border-radius: 3px;
      text-align: center;
      z-index: 21;
  
      h2 {
        padding: 0.5em;
        margin: 0;
        font-weight: normal;
        border-bottom: 1px solid #ddd;
        background-color: #f3f3f3;
      }
  
      > div {
        padding: 1em;
      }
    }
  
    &.dt-button-collection-title {
      text-align: center;
      padding: 0.3em 0 0.5em;
      font-size: 0.9em;
  
      &:empty {
        display: none;
      }
    }
  
    &.dt-button-collection {
      position: absolute;
      z-index: 2001;
  
      div {
        &.dropdown-menu {
          display: block;
          z-index: 2002;
        }
  
        &.dt-button-collection-title {
          background-color: white;
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
  
      &.fixed {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -75px;
        border-radius: 0;
  
        &.two-column {
          margin-left: -200px;
        }
  
        &.three-column {
          margin-left: -225px;
        }
  
        &.four-column {
          margin-left: -300px;
        }
      }
  
      > :last-child {
        display: block !important;
        -webkit-column-gap: 8px;
        -moz-column-gap: 8px;
        -ms-column-gap: 8px;
        -o-column-gap: 8px;
        column-gap: 8px;
  
        > * {
          -webkit-column-break-inside: avoid;
          break-inside: avoid;
        }
      }
  
      &.two-column {
        width: 400px;
  
        > :last-child {
          padding-bottom: 1px;
          -webkit-column-count: 2;
          -moz-column-count: 2;
          -ms-column-count: 2;
          -o-column-count: 2;
          column-count: 2;
        }
      }
  
      &.three-column {
        width: 450px;
  
        > :last-child {
          padding-bottom: 1px;
          -webkit-column-count: 3;
          -moz-column-count: 3;
          -ms-column-count: 3;
          -o-column-count: 3;
          column-count: 3;
        }
      }
  
      &.four-column {
        width: 600px;
  
        > :last-child {
          padding-bottom: 1px;
          -webkit-column-count: 4;
          -moz-column-count: 4;
          -ms-column-count: 4;
          -o-column-count: 4;
          column-count: 4;
        }
      }
  
      .dt-button {
        border-radius: 0;
      }
  
      &.fixed {
        max-width: none;
  
        &:before, &:after {
          display: none;
        }
      }
    }
  
    &.dt-button-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
    }
  
    &.dt-buttons {
      button.btn.processing, div.btn.processing, a.btn.processing {
        color: $black-2;
      }
  
      button.btn.processing:after, div.btn.processing:after, a.btn.processing:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        margin: -8px 0 0 -8px;
        box-sizing: border-box;
        display: block;
        content: " ";
        border: 2px solid #282828;
        border-radius: 50%;
        border-left-color: transparent;
        border-right-color: transparent;
        animation: dtb-spinner 1500ms infinite linear;
        -o-animation: dtb-spinner 1500ms infinite linear;
        -ms-animation: dtb-spinner 1500ms infinite linear;
        -webkit-animation: dtb-spinner 1500ms infinite linear;
        -moz-animation: dtb-spinner 1500ms infinite linear;
      }
    }
  }
  
  @media screen and (max-width: 767px) {
    div.dt-buttons {
      float: none;
      width: 100%;
      text-align: center;
      margin-bottom: 0.5em;
  
      a.btn {
        float: none;
      }
    }
  }