/* jQuery Growl
 * Copyright 2015 Kevin Sylvestre
 * 1.3.5
 */

 .ontop, #growls-default, #growls-tl, #growls-tr, #growls-bl, #growls-br, #growls-tc, #growls-bc, #growls-cc, #growls-cl, #growls-cr {
	z-index: 50000;
	position: fixed;
  }
  
  #growls-default {
	top: 10px;
	right: 10px;
  }
  
  #growls-tl {
	top: 10px;
	left: 10px;
  }
  
  #growls-tr {
	top: 10px;
	right: 10px;
  }
  
  #growls-bl {
	bottom: 10px;
	left: 10px;
  }
  
  #growls-br {
	bottom: 10px;
	right: 10px;
  }
  
  #growls-tc {
	top: 10px;
	right: 10px;
	left: 10px;
  }
  
  #growls-bc {
	bottom: 10px;
	right: 10px;
	left: 10px;
  }
  
  #growls-cc {
	top: 50%;
	left: 50%;
	margin-left: -125px;
  }
  
  #growls-cl {
	top: 50%;
	left: 10px;
  }
  
  #growls-cr {
	top: 50%;
	right: 10px;
  }
  
  #growls-tc .growl, #growls-bc .growl {
	margin-left: auto;
	margin-right: auto;
  }
  
  .growl {
	opacity: 0.8;
	filter: alpha(opacity = 80);
	position: relative;
	border-radius: 4px;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
  
	&.growl-incoming, &.growl-outgoing {
	  opacity: 0;
	  filter: alpha(opacity = 0);
	}
  
	&.growl-small {
	  width: 200px;
	  padding: 5px;
	  margin: 5px;
	}
  
	&.growl-medium {
	  width: 250px;
	  padding: 10px;
	  margin: 10px;
	}
  
	&.growl-large {
	  width: 300px;
	  padding: 15px;
	  margin: 15px;
	}
  
	&.growl-default {
	  color: $white;
	  background: $primary;
	}
  
	&.growl-error {
	  color: $white;
	  background: #fb5454;
	}
  
	&.growl-notice, &.growl-sucess {
	  color: $white;
	  background: #4ecc48;
	}
  
	&.growl-warning {
	  color: $white;
	  background: #ffa70b;
	}
  
	.growl-close {
	  cursor: pointer;
	  float: right;
	  font-size: 14px;
	  line-height: 18px;
	  font-weight: normal;
	  font-family: helvetica, verdana, sans-serif;
	}
  
	.growl-title {
	  font-size: 15px;
	  line-height: 24px;
	}
  
	.growl-message {
	  font-size: 14px;
	  line-height: 18px;
	}
  }