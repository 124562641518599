.morris-hover {
	position: absolute;
	z-index: 10;
  
	&.morris-default-style {
	  border-radius: 10px;
	  padding: 6px;
	  color: #666;
	  background: $white-8;
	  border: solid 1px rgba(230, 230, 230, 0.1);
	  font-family: sans-serif;
	  font-size: 12px;
	  text-align: center;
  
	  .morris-hover-row-label {
		font-weight: 400;
		margin: 0.25em 0;
	  }
  
	  .morris-hover-point {
		white-space: nowrap;
		margin: 0.1em 0;
	  }
	}
}

  #morrisBar8, #morrisBar9 {
  &.morris-donut-charts svg text {
    color: #2f4c7d !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    font-family: "Poppins", sans-serif !important;
  }
}

#morrisBarchart1 svg text{
	font-family: "Poppins", sans-serif !important;
	font-weight: 500 !important;
	font-size: 15px !important;
	transform: unset;

	+ text {
		transform: translateY(10px) !important;
	}
}