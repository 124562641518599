.text {
    height: 18px;
    display: inline-block;
    text-decoration: none;
  }
  
  li.folder-group {
    height: auto;
  }
  
  .folder-group ul {
    padding-left: 20px;
    display: none;
  }
  
  .folder {
    background: url('../plugins/dtree/images/misc.png') 0px -39px no-repeat;
    padding-left: 20px;
    height: 18px;
    display: inline-block;
    transition: all 0.5s ease-in-out;
  }
  
  .folder-open {
    background: url('../plugins/dtree/images/misc.png') -21px -39px no-repeat;
    padding-left: 20px;
    height: 18px;
    display: inline-block;
    transition: all 0.5s ease-in-out;
  }
  
  .main {
    background: url('../plugins/dtree/images/misc.png') 2px 0 no-repeat;
    width: 18px;
    height: 18px;
    display: inline-block;
  }
  
  .folder-group {
    background: url('../plugins/dtree/images/misc.png') -46px -2px repeat-y;
  
    &.last {
      background: none;
    }
  }
  
  .plus {
    background: url('../plugins/dtree/images/misc.png') 0px -59px no-repeat;
    padding-left: 20px;
    display: inline-block;
    height: 18px;
  }
  
  .last .plus {
    background: url('../plugins/dtree/images/misc.png') 0px -82px no-repeat;
    padding-left: 20px;
    display: inline-block;
    height: 18px;
  }
  
  .folder-group.last .minus {
    background: url('../plugins/dtree/images/misc.png') -18px -82px no-repeat;
    padding-left: 20px;
    display: inline-block;
    height: 18px;
  }
  
  .minus {
    background: url('../plugins/dtree/images/misc.png') -18px -59px no-repeat;
    padding-left: 20px;
    display: inline-block;
    height: 18px;
  }
  
  .join {
    background: url('../plugins/dtree/images/misc.png') 0 -20px no-repeat;
    width: 18px;
    height: 18px;
    display: inline-block;
  }
  
  .join-last .join {
    background: url('../plugins/dtree/images/misc.png') -18px -20px no-repeat;
    width: 18px;
    height: 18px;
    display: inline-block;
  }
  
  .dTree {
    ul li {
      height: 18px;
      display: block;
      width: 300px;
      line-height: 20px;
      margin: 0px;
      padding: 0px;
      list-style-type: none;
    }
  
    a {
      height: 18px;
      display: inline-block;
      padding-top: 0px;
      color: #74829c;
      text-decoration: none;
    }
  
    .page-tree {
      background: url('../plugins/dtree/images/misc.png') no-repeat;
      width: 25px;
      height: 18px;
      display: inline-block;
      min-height: auto;
    }
  
    li.folder-group {
      height: auto;
    }
  }