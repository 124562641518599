@import "custom/fonts/fonts";
@import "variables";


/* ###### plugins ####### */

@import "plugins/accordion/accordion";
@import "plugins/primo-slider/primo-slider";
@import "plugins/bootstrap-datepicker/bootstrap-datepicker";
@import "plugins/bootstrap-daterangepicker/daterangepicker";
@import "plugins/calendar/clndr";
@import "plugins/charts-c3/c3-chart";
@import "plugins/charts-nvd3/nv.d3";
@import "plugins/datatable/dataTables.bootstrap5";
@import "plugins/datatable/buttons.bootstrap5.min";
@import "plugins/datatable/responsive.bootstrap5";
@import "plugins/date-picker/spectrum";
@import "plugins/dtree/dtree";
@import "plugins/edit-table/edit-table";
@import "plugins/fancyuploder/fancy_fileupload";
@import "plugins/fileuploads/fileupload";
@import "plugins/formwizard/smart_wizard_all.min";
@import "plugins/fullcalendar/fullcalendar";
@import "plugins/gallery/gallery";
@import "plugins/ion.rangeSlider/ion.rangeSlider";
@import "plugins/jquery-countdown/jquery.countdown";
@import "plugins/jvectormap/jquery-jvectormap-2.0.2";
@import "plugins/leaflet/leaflet";
@import "plugins/morris/morris";
@import "plugins/multipleselect/multiple-select";
@import "plugins/notify/jquery.growl";
@import "plugins/notify/notifIt";
@import "plugins/owl-carousel/owl.carousel";
@import "plugins/pickr-master/classic.min";
@import "plugins/pickr-master/monolith.min";
@import "plugins/pickr-master/nano.min";
@import "plugins/p-scroll/perfect-scrollbar";
@import "plugins/quill/quill.bubble";
@import "plugins/quill/quill.snow";
@import "plugins/rating/examples";
@import "plugins/ratings-2/star-rating-svg";
@import "plugins/select2/select2.min";
@import "plugins/sidebar/sidebar";
@import "plugins/single-page/main";
@import "plugins/summer-note/summernote1";
@import "plugins/sweet-alert/sweetalert";
@import "plugins/tabs/tabs";
@import "plugins/telephoneinput/telephoneinput";
@import "plugins/time-picker/jquery.timepicker";
@import "plugins/treeview/treeview";
@import "plugins/wysiwyag/richtext";