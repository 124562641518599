#ui_notifIt {
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
    overflow: hidden;
    -webkit-box-shadow: 0px 3px 5px $black-3;
    -moz-box-shadow: 0px 3px 5px $black-3;
    -o-box-shadow: 0px 3px 5px $black-3;
    box-shadow: 0px 3px 5px $black-3;
    -wekbit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    z-index: 99999;
  
    &:hover {
      opacity: 1 !important;
    }
  
    p {
      text-align: center;
      font-family: sans-serif;
      font-size: 14px;
      padding: 0;
      margin: 0;
    }
  }
  
  #notifIt_close {
    position: absolute;
    color: $white;
    top: 0;
    padding: 0px 5px;
    right: 0;
  
    &:hover {
      background-color: $white-3;
    }
  }
  
  /* Color setup */
  
  /* You are free to change all of this */
  
  #ui_notifIt {
    &.success {
      background-color: $success;
      color: white;
    }
  
    &.error {
      background-color: $danger;
      color: white;
    }
  
    &.warning {
      background-color: $warning;
      color: white;
    }
  
    &.info {
      background-color: $info;
      color: white;
    }
  
    &.default {
      background-color: #ebecf1;
      color: #1a1630;
    }
  }
  
  /* notifit confirm */
  
  .notifit_confirm_bg, .notifit_prompt_bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $white-8;
  }
  
  .notifit_confirm *, .notifit_prompt * {
    font-family: sans-serif;
  }
  
  .notifit_confirm, .notifit_prompt {
    position: fixed;
    top: 0;
    left: 0;
    padding: 30px 30px 0px 30px;
    background-color: #EEE;
    border: 1px solid $black-1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 2px 10px $black-2;
    box-shadow: 0px 2px 10px $black-2;
  }
  
  .notifit_confirm_accept, .notifit_confirm_cancel, .notifit_prompt_accept, .notifit_prompt_cancel {
    padding: 10px 20px;
    color: $white;
    border: 1px solid $black-1;
    background-color: #444;
    margin: 10px;
    outline: 0;
    cursor: pointer;
    display: inline-block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
  }
  
  .notifit_prompt_accept:hover, .notifit_prompt_cancel:hover {
    background-color: #666;
  }
  
  .notifit_confirm_accept {
    background-color: #9ACD32;
  
    &:hover {
      background-color: #ABDE43;
    }
  }
  
  .notifit_confirm_cancel {
    background-color: #FF4500;
  
    &:hover {
      background-color: #FF5611;
    }
  }
  
  .notifit_confirm_message {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .notifit_prompt_message {
    color: #444;
    margin-top: 0;
    text-align: center;
  }
  
  .notifit_prompt_input {
    text-align: center;
    font-family: sans-serif;
    font-size: 14px;
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid #AAA;
    color: #444;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
  }
  
  .notifit_prompt {
    text-align: center;
  }